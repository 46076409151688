import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { ExpenseGuard } from './guard/expense.guard';
import { ParentUserComponent } from './parent-user/parent-user.component';
import { ChildrenComponent } from './children/children.component';
import { ReportsComponent } from './reports/reports.component';
import { KiddictionComponent } from './kiddiction/kiddiction.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { QuestionsComponent } from './questions/questions.component';
import { TrComponent } from './tr/tr.component';
import { OwnerComponent } from './owner/owner.component';
import { TeacherComponent } from './teacher/teacher.component';
import { NotificationsNewComponent } from './notifications-new/notifications-new.component';


const routes: Routes = [
  { path: '', component: ParentUserComponent, canActivate: [ExpenseGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'parent-users', component: ParentUserComponent, canActivate: [ExpenseGuard] },
  { path: 'children', component: ChildrenComponent, canActivate: [ExpenseGuard] },
  { path: 'reports', component: ReportsComponent, canActivate: [ExpenseGuard] },
  { path: 'notifications', component: NotificationsComponent, canActivate: [ExpenseGuard] },
  { path: 'notifications-new', component: NotificationsNewComponent, canActivate: [ExpenseGuard] },
  { path: 'kiddiction-data', component: KiddictionComponent, canActivate: [ExpenseGuard] },
  { path: 'tr', component: TrComponent, canActivate: [ExpenseGuard] },
  { path: 'owner', component: OwnerComponent, canActivate: [ExpenseGuard] },
  { path: 'questions', component: QuestionsComponent, canActivate: [ExpenseGuard] },
  { path: 'teachers', component: TeacherComponent, canActivate: [ExpenseGuard] },
  { path: '', redirectTo: 'parent-users', pathMatch: 'full' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }