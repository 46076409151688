import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';


import { tap, delay, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from '../Models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  isUserLoggedIn: boolean = false;
  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(name: string, password: string) {
    return this.http.post<any>(`${environment.apiURL}/admin/login`, { name, password })
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && user.data.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          // user.data.token = user.token;
          this.isUserLoggedIn = true;
          localStorage.setItem('currentUser', JSON.stringify(user.data.user));
          localStorage.setItem('token', user.data.token);
          localStorage.setItem('isUserLoggedIn', this.isUserLoggedIn ? "true" : "false");
          this.currentUserSubject.next(user.data.user);
          return of(this.isUserLoggedIn).pipe(
            delay(1000),
            tap(val => {
              console.log("Is User Authentication is successful: " + val);
            })
          );
        }

        return user;
      }));
  }
  newLogin(name: string, password: string) {
    return this.http.post<any>(`${environment.apiURL}/administrator-authenticate`, { name, password })
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          // user.data.token = user.token;
          this.isUserLoggedIn = true;
          localStorage.setItem('currentUser', JSON.stringify(user.administrator));
          localStorage.setItem('token', user.token);
          localStorage.setItem('isUserLoggedIn', this.isUserLoggedIn ? "true" : "false");
          this.currentUserSubject.next(user.administrator);
          return of(this.isUserLoggedIn).pipe(
            delay(1000),
            tap(val => {
              console.log("Is User Authentication is successful: " + val);
            })
          );
        }

        return user;
      }));
  }
  logout(): void {
    this.isUserLoggedIn = false;
    localStorage.removeItem('isUserLoggedIn');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
    this.currentUserSubject.next(null);
  }


}