import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from 'src/environments/environment';
import { PossiblePronounciationComponent } from '../common/possible-pronounciation/possible-pronounciation.component';
import { RepositoryService } from '../shared/repository.service';
import { AnswerOptionsComponent } from './answer-options/answer-options.component';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css']
})
export class QuestionsComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isLoading = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  sortBy = 'created_at';
  sortOrder = 'desc';
  keyword = '';
  pageSizeOptions: number[] = [10, 20, 50, 100];
  public displayedColumns = ['description', 'story_detail', 'fk_chapter_id', 'kiddiction_gibberish_cutoff', 'kiddiction_repeat_cutoff', 'language', 'is_active', 'is_open_ended', 'answer_popup'];
  public dataSource = new MatTableDataSource<any>();

  constructor(private repoService: RepositoryService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {
    this.pageSize = environment.perPage;
  }

  ngOnInit(): void {
    this.loadData();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  loadData() {
    this.isLoading = true;
    let URL = `admin/questions-answers?keyword=${this.keyword}&sortBy=${this.sortBy}&sortOrder=${this.sortOrder}&page=${this.currentPage}&perPage=${this.pageSize}`;
    this.repoService.getData(URL)
      .subscribe((data: any) => {
        this.dataSource.data = data.data;
        setTimeout(() => {
          this.paginator.pageIndex = this.currentPage;
          this.paginator.length = data.total;
        });
        this.isLoading = false;
      }, error => {
        console.log(error);
        this.isLoading = false;
      });
  }

  openDialog(items): void {
    // console.log(items)
    const dialogRef = this.dialog.open(AnswerOptionsComponent, {
      width: '100%',
      data: items
    });
  }

  customSort(event) {
    console.log(event)
    this.sortBy = event.active;
    this.sortOrder = event.direction;
    this.loadData();
  }
  pageChanged(event: PageEvent) {
    console.log({ event });
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.loadData();
  }
  public doFilter = (value: string) => {

    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  openPopupForUpdatePossibleAns() {
    const dialogRef = this.dialog.open(PossiblePronounciationComponent, {
      width: '50%',
      data: { name: null, confirm: true },
    })
  }
}
