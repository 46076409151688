import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { User } from './Models/user.model';
import { AuthService } from './services/auth.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'ios-dashboard';
  logoUrl = '';
  loggedIn = false;
  isUserLoggedIn = false;
  currentUser: User;
  constructor(private authService: AuthService) {
    this.authService.currentUser.subscribe(x => this.currentUser = x);
  }

  ngOnInit() {
    this.logoUrl = environment.logoUrl;
    let storeData = localStorage.getItem("isUserLoggedIn");
    console.log("StoreData: " + storeData);
    if (storeData != null && storeData == "true")
      this.isUserLoggedIn = true;
    else
      this.isUserLoggedIn = false;
  }

  // checkLogin() {
  //   let token = localStorage.getItem('token');
  //   // let token2=localStorage.getItem('dsfn');
  //   // console.log(token2);
  //   if (token) {
  //     this.loggedIn = true;
  //   }
  // }
}
