import {
  Injectable
} from '@angular/core';
import {
  HttpClient
} from '@angular/common/http';
import {
  Observable,
  throwError
} from 'rxjs';
import {
  catchError,
  retry
} from 'rxjs/operators';
import {
  NotificationUserData
} from '../Models/notificationUserData.model'
import { environment } from 'src/environments/environment';
interface Result {
  data: NotificationUserData[];
  message?: string;
  status?: Boolean;
}
@Injectable()
export class NotificaitonsService {
  constructor(private http: HttpClient) { }
  getNotificaitonUserData() {
    // now returns an Observable of Config
    return this.http.get<Result>(environment.apiURL + '/admin/notifications/user-data');
  }
  sendNotification(sendToUserIds: number[], title: string, body: string, image: File, filters) {
    const data = new FormData();
    for (const id of sendToUserIds) {
      data.append('sendToUserIds[]', id.toString());
    }
    data.append('title', title);
    data.append('body', body);
    if (image) {
      data.append('image', image, image.name);
    }
    data.append('filters', JSON.stringify(filters));

    return this.http.post(environment.apiURL + '/admin/send-notification', data);
  }
  getFilteredNotificaitonUserData(story: string, chapter: any, totalDuration: any, noOfQuestionsAnswered: any) {
    // now returns an Observable of Config
    return this.http.get<Result>(environment.apiURL + '/admin/notifications/user-data?story=${story}&chapter=${chapter}&totalDuration=${totalDuration}&noOfQuestionsAnswered=${noOfQuestionsAnswered}');
  }
  lastSyncData() {
    return this.http.get<Result>(environment.apiURL + '/admin/last-sync-report');
  }
  syncData() {
    return this.http.get<Result>(environment.apiURL + '/admin/sync-report');
  }
}
