import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})
export class FilterComponent implements OnInit {

  chapterList: any;
  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  clearFilter(): void {
    this.data.story = null;
    this.data.chapter = null;
    this.data.totalDuration = null;
    this.data.filterCountry = null;
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.storySelection(this.data.story);
  }

  storySelection(id) {
    if (id == null) {
      this.chapterList = this.data.formData.stories;
    } else {
      this.chapterList = this.data.formData.stories.filter(element => {
        if (element.id == id)
          return element;
      });
    }
  }
}
