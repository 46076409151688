import {
  Component,
  ElementRef,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  MatTableDataSource
} from '@angular/material/table';
import {
  User
} from '../Models/user.model';
import {
  MatButtonModule
} from '@angular/material/button';
import {
  NotificaitonsService
} from '../services/notifications.service'
import { NgbModal, NgbActiveModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {

  constructor(private notificationsService: NotificaitonsService, private modalService: NgbModal) { }
  //notificationUserData={};
  sendToUserIds = [];
  data = [];
  title = '';
  body = '';
  checked = true;
  allSelected = true;
  story = '';
  chapter = '';
  isLoading = true;
  totalDuration = '';
  noOfQuestionsAnswered = '';
  notificationUserData: MatTableDataSource<Element>;
  displayedNotificationsUserData = ['check', 'user_id', 'name', 'total_duration', 'questions_answered', 'stories_visited', 'chapters_visited'];
  ngOnInit(): void {
    this.notificationsService.getNotificaitonUserData()
      // clone the data object, using its known Config shape
      .subscribe(res => {
        //console.log(res);
        this.data = res.data;
        this.data.forEach(user => {
          user.checked = true;
          this.sendToUserIds.push(user.user_id);
        });
        this.notificationUserData = new MatTableDataSource(this.data);
        this.isLoading = false;
        //this.userData = new MatTableDataSource(this.users);
        // console.log(this.data);
        // console.log(this.notificationUserData);
        //console.log(this.sendToUserIds);

      });
  }
  addOrRemoveUser(user_id: number) {
    let check = true;
    for (let i = 0; i < this.sendToUserIds.length; i++) {

      if (this.sendToUserIds[i] === user_id) {
        this.sendToUserIds.splice(i, 1);
        i--;
        check = false;
      }
    }
    if (check) {
      this.sendToUserIds.push(user_id);
    }
    console.log(this.sendToUserIds);
  }
  open(content) {
    this.modalService.open(content);

    //modalRef.componentInstance.name = 'World';
  }
  sendNotification() {
    //console.log(this.selectedId);
    console.log(this.title);
    console.log(this.body);
    this.modalService.dismissAll();
    let filters = null;
    this.notificationsService.sendNotification(this.sendToUserIds, this.title, this.body, null, filters).subscribe(res => {
      console.log(res);
    });
    this.title = '';
    this.body = '';
  }
  applyFilter() {
    // console.log('filter clicked');
    // console.log('story: '+this.story);
    // console.log('chapter: '+this.chapter);
    // console.log(typeof (this.chapter));
    // console.log('totalDuration: '+this.totalDuration);
    // console.log('noOfQuestionsAnswered: '+this.noOfQuestionsAnswered);
    this.isLoading = true;
    this.notificationsService.getFilteredNotificaitonUserData(this.story, this.chapter, this.totalDuration, this.noOfQuestionsAnswered).subscribe(res => {
      console.log(res);
      this.data = res.data;
      console.log(this.data);
      this.sendToUserIds = [];
      this.data.forEach(user => {
        user.checked = true;
        this.sendToUserIds.push(user.user_id);
      });
      this.notificationUserData = new MatTableDataSource(this.data);
      this.isLoading = false;
      //this.userData = new MatTableDataSource(this.users);
      // console.log(this.data);
      // console.log(this.notificationUserData);
      console.log(this.sendToUserIds);
    });
    this.story = '';
    this.chapter = '';
    this.totalDuration = '';
    this.noOfQuestionsAnswered = '';
  }
  selectUnselectAll() {
    if (this.allSelected) {
      this.allSelected = false;
      this.data.forEach(user => {
        user.checked = false;
        this.sendToUserIds = [];
      });
      this.notificationUserData = new MatTableDataSource(this.data);
    } else {
      this.allSelected = true;
      this.data.forEach(user => {
        user.checked = true;
        this.sendToUserIds.push(user.user_id);
      });
      this.notificationUserData = new MatTableDataSource(this.data);
    }
  }
}
