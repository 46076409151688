import { Component, OnInit } from '@angular/core';

import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';

import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';

@Component({
   selector: 'app-login',
   templateUrl: './login.component.html',
   styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
   loading = false;
   submitted = false;
   name: string;
   password: string;
   formData: FormGroup;
   error = '';
   logoUrl = '';

   constructor(private authService: AuthService, private router: Router, private formBuilder: FormBuilder) {
      // redirect to home if already logged in
      if (this.authService.currentUserValue) {
         this.router.navigate(['/']);
      }
   }

   ngOnInit() {
      this.logoUrl = environment.logoUrl;
      this.formData = this.formBuilder.group({
         name: ['', [Validators.required, Validators.email]],
         password: ['', Validators.required]
      });
   }
   // convenience getter for easy access to form fields
   get f() { return this.formData.controls; }

   onClickSubmit(data: any) {
      this.submitted = true;
      this.error = '';

      // stop here if form is invalid
      if (this.formData.invalid) {
         return;
      }

      this.loading = true;
      // this.authService.login(this.name, this.password)
      this.authService.newLogin(this.formData.value.name, this.formData.value.password)
         .subscribe(data => {
            if (data) this.router.navigate(['/parent-users']);
         },
            error => {
               if(error.status = 422)
                  this.error = 'Incorrect Email or Password. Please try again.';
               this.loading = false;
            });
   }
}