import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { RepositoryService } from 'src/app/shared/repository.service';
import { DialogData } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-possible-pronounciation',
  templateUrl: './possible-pronounciation.component.html',
  styleUrls: ['./possible-pronounciation.component.css']
})
export class PossiblePronounciationComponent implements OnInit {
  word: null;
  pronunciations: null;
  updateIds: any;
  id: null;
  sbtButton: string = "add";
  loading: boolean = false;
  gridShow: boolean = false;
  // public displayedColumns = ['description', 'question_data', 'possilbe_answers_data', 'action'];
  // public dataSource = new MatTableDataSource<any>();
  dataSource: any;

  constructor(
    public dialogRef: MatDialogRef<any>,
    public httpService: RepositoryService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    console.log(this.data);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  searchWord() {
    this.loading = true;
    this.gridShow = false;
    this.sbtButton = "add";
    this.id = null;
    this.pronunciations = null;
    this.httpService.getData('admin/get-possible-word/' + this.word)
      // clone the data object, using its known Config shape
      .subscribe((res: any) => {
        console.log(res);
        if (res.status) {
          this.sbtButton = "update";
          if (res.from == "possible") {
            this.id = res.data[0].id;
            this.pronunciations = res.data[0].pronunciations;
          } else {
            this.dataSource = res.data;
            this.gridShow = true;
          }
          
        } else {
          this.sbtButton = "add";
        }
        this.loading = false;
      });
  }
  savePossibleAns() {
    this.httpService.update('admin/update-possible-pronounciation', { id: this.id, word: this.word, pronunciations: this.pronunciations, updateIds: this.updateIds })
      // clone the data object, using its known Config shape
      .subscribe((res: any) => {
        console.log(res);
        this.clearForm();
        this._snackBar.open("Updated", "Data Updated", {
          duration: 1000
        });
      });
  }
  insertPossibleAnswer() {
    this.httpService.create('admin/insert-possible-pronounciation', { word: this.word, pronunciations: this.pronunciations })
      // clone the data object, using its known Config shape
      .subscribe((res: any) => {
        console.log(res);
        this.clearForm();
        this._snackBar.open("Saved", "Data Saved", {
          duration: 1000
        });
      });
  }

  clearForm() {
    this.sbtButton = "add";
    this.id = null;
    this.word = null;
    this.pronunciations = null;
    this.gridShow = false;
  }

  showHide() {
    if (this.pronunciations != null) {
      return false;
    } else {
      return true;
    }
  }

  useThis(desc, word) {
    console.log(word);
    this.word = word;
    this.pronunciations = desc;
    this.updateIds = this.dataSource.map(({ id }) => id)
    console.log(this.updateIds);
  }
  
}
