import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/internal/Observable';



@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem("token");
     req = req.clone({
    //   setHeaders: {
    //     'Referrer-Policy': 'origin',
    //     'Access-Control-Allow-Origin': '*',
    //     'Content-Type' : 'application/json',
    //     'Accept'       : 'application/json',
    //     'Authorization': `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiYzBmZTAyOTM1YTBjMTI0OWRlMWZhMjEyYTg0M2Q0NTI5OGY0OGE0ODJiNDJkNjU0ZmMzYzRlZjgwZTVkNjMzZjFjZTRjYjBlNDQ5NGIxZTMiLCJpYXQiOjE2MDE1ODkxMTIsIm5iZiI6MTYwMTU4OTExMiwiZXhwIjoxNjMzMTI1MTEyLCJzdWIiOiIxOSIsInNjb3BlcyI6W119.P-Wl3vadnjtHXagjJbshMk564pKzTYnnwkDNc7lnbF-LQIr9n8TqYz87Cam_t7se9C8Iq3oOu6KJdZgAMnGn6zEwFxWPSLp8GZcorv8qyvZwUHK1WV57bIljMoYpQJYSQfQMiDUkYTd3IQTqQlz3DOOqUp-uFe70D9qsMciENuZeSG5mBdjEGhlsQUJFc-op1bihsUdsLJ92jNm03NjYqfs_odXTv-4WOKSvJIBu7geMZf-rfuPoOjLr1oCraomvz_nq0CeJGK7nzRhXHaZlfbO6qcnMTUeof4FV15TvImOMlzO3MyukfCUMXIFmV9V4CUz8X_ohQIjo2GSDqE3XTIeSJRSY6GcJlbNlxPrQI0LNCoF-teyRpfv7d71LIAS1FsAIv21Y3bBKr5sopQk91l7w4NJldgtgnnz0wMjF0fn92D8za9SMkdwQdn2u56gEnhnPK0u2nW25PaEBKAQnqfPaX0rTlsk4xnB7k-ZAoFlt4ZxfS3prwcmsfXBGHOYxKfH0L5tUXSXjIKHNzNZumdiclWRBW4ibfiitOkv2t_vHnClhdXFWgVZmjDJpIMo6iOp_oHvd2XecfLa3x_yrT4Kc6o2JUMoyksUUlqSw1oUM7KSktierfYHOYFPEf2w2aDOaI5QiGuKcuuNLFClxWzARxR63rXzK_PAEKqcd3to`,
    //   },
    // });
    headers: req.headers.set("Authorization",
            "Bearer " + token)
        });
    return next.handle(req);
  }
}
