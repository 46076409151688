import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DatePipe } from '@angular/common';
import { KiddictionService } from '../services/kiddiction.service';
import * as XLSX from 'xlsx';
import { PossibleAnswerComponent } from './possible-answer/possible-answer.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KeywordsComponent } from './keywords/keywords.component';
import { InlineEditAnswerComponent } from './inline-edit-answer/inline-edit-answer.component';
import { RepositoryService } from '../shared/repository.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-kiddiction',
  templateUrl: './kiddiction.component.html',
  styleUrls: ['./kiddiction.component.css']
})
export class KiddictionComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isLoading = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  sortBy = 'date';
  sortOrder = 'desc';
  isOpenEnded = null;
  iosData = null;
  androidData = null;
  keyword = '';
  pageSizeOptions: number[] = [10, 20, 50, 100];
  public displayedColumns = ['date', 'name', 'age', 'question', 'platform', 'actual_answer', 'raw_answer',  'matched_answer','matched_answer_from', 'is_used_screen', 'kiddiction_score', 'is_open_ended', 'possible_answer', 'keywords'];
  kiddictionDatafileName = 'kiddictionData.xlsx';
  public dataSource = new MatTableDataSource<any>();

  constructor(
    private kiddictionService: KiddictionService,
    private datePipe: DatePipe,
    private repoService: RepositoryService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {
    this.pageSize = environment.perPage;
  }

  ngOnInit(): void {
    this.loadData();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  loadData() {
    this.isLoading = true;
    this.isOpenEnded = this.isOpenEnded == true ? 1 : null;
    this.iosData = this.iosData == true ? 1 : null;
    this.androidData = this.androidData == true ? 1 : null;
    let URL = `admin/kiddiction-data?keyword=${this.keyword}&sortBy=${this.sortBy}&sortOrder=${this.sortOrder}&page=${this.currentPage}&perPage=${this.pageSize}&isOpenEnded=${this.isOpenEnded}&iosData=${this.iosData}&androidData=${this.androidData}`;
    this.repoService.getData(URL)
      .subscribe((data: any) => {
        this.dataSource.data = data.data;
        setTimeout(() => {
          this.paginator.pageIndex = this.currentPage;
          this.paginator.length = data.total;
        });
        this.isLoading = false;
      }, error => {
        console.log(error);
        this.isLoading = false;
      });
  }
  public redirectToDetails = (id: string) => {

  }

  public redirectToUpdate = (id: string) => {

  }
  customSort(event) {
    console.log(event)
    this.sortBy = event.active;
    this.sortOrder = event.direction;
    this.loadData();
  }
  pageChanged(event: PageEvent) {
    console.log({ event });
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.loadData();
  }
  public doFilter = (value: string) => {

    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  openDialog(items): void {
    // console.log(items)
    const dialogRef = this.dialog.open(PossibleAnswerComponent, {
      width: '500px',
      data: items
    });
  }

  openKeywordDialog(items): void {
    const dialogRef = this.dialog.open(KeywordsComponent, {
      width: '500px',
      data: items
    });
  }

  openDialogBox(action, obj, index) {
    obj.action = action;
    const dialogRef = this.dialog.open(InlineEditAnswerComponent, {
      width: '400px',
      data: obj
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result.event == 'Add') {
        // this.addRowData(result.data);
      } else if (result.event == 'Update') {
        this.updateRowData(result.data, index);
      } else if (result.event == 'Delete') {
        // this.deleteRowData(result.data);
      }
    });
  }

  updateRowData(row_obj, index) {
    this.dataSource.data = this.dataSource.data.filter((value, key) => {
      if (value.id == row_obj.id) {
        value.actual_answer = row_obj.actual_answer;
      }
      return true;
    });
    this.kiddictionService.updateActualAnswer(row_obj).subscribe(res => {
      this.loadData();
    }
    );

  }

  exportexcel() {
    let data;
    let URL = `admin/kiddiction-data?csv=true&isOpenEnded=${this.isOpenEnded}`;
    this.repoService.getData(URL)
      .subscribe((data: any) => {
        data = data.data.map(a => {
          return {
            ...a,
            date: this.datePipe.transform(a.date, 'MMM d, y, h:mm:ss a ')
          }
        })
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, { header: ['date', 'name', 'age', 'question', 'actual_answer', 'matched_answer', 'kiddiction_score'] });
        // console.log("test ");
        // console.log(ws);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, this.kiddictionDatafileName);
        // const workSheet = XLSX.utils.json_to_sheet(this.sessionDatesData, {header:['user_id', 'signed_up', 'last_visited', 'sessions']});
        // const workBook: XLSX.WorkBook = XLSX.utils.book_new();
        // XLSX.utils.book_append_sheet(workBook, workSheet, 'SheetName');
        // XLSX.writeFile(workBook, 'filename.xlsx');
      }, error => {
        console.log(error);
        this.isLoading = false;
      });
  }


}
