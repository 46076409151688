import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { KiddictionService } from 'src/app/services/kiddiction.service';
interface Languages {
  value: number;
  viewValue: string;
}
interface Active {
  value: number;
  viewValue: string;
}
@Component({
  selector: 'app-answer-options',
  templateUrl: './answer-options.component.html',
  styleUrls: ['./answer-options.component.css']
})

export class AnswerOptionsComponent implements OnInit {
  languages: Languages[] = [
    { value: 0, viewValue: 'Default' },
    { value: 1, viewValue: 'Hindi' }
  ];
  active: Active[] = [
    { value: 0, viewValue: 'InActive' },
    { value: 1, viewValue: 'Active' }
  ];
  constructor(
    public dialogRef: MatDialogRef<AnswerOptionsComponent>,
    private _snackBar: MatSnackBar,
    private kiddictionService: KiddictionService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  saveAnswer(item,action: string) {
    this.kiddictionService.updateAnswer(item)
      // clone the data object, using its known Config shape
      .subscribe(res => {
        console.log(res);
        this._snackBar.open("Saved", action, {
          duration: 1000
        });
      });
  }
  savePossibleAns(item,answer, action: string) {
    this.kiddictionService.updatePossibleAnswer(item,answer)
      // clone the data object, using its known Config shape
      .subscribe(res => {
        console.log(res);
        this._snackBar.open("Saved", action, {
          duration: 1000
        });
      });
  }
  saveKeywords(item, action: string) {
    this.kiddictionService.updateKeywords(item)
      // clone the data object, using its known Config shape
      .subscribe(res => {
        console.log(res);
        this._snackBar.open("Saved", action, {
          duration: 1000
        });
      });
  }
  insertPossibleAnswer(id,desc,ios,android, action: string) {
    this.kiddictionService.insertPossibleAnswer(id,desc,ios,android)
      // clone the data object, using its known Config shape
      .subscribe(res => {
        console.log(res);
        this._snackBar.open("Saved", action, {
          duration: 1000
        });
      });
  }
  insertKeywords(id,desc,ios,android, action: string) {
    this.kiddictionService.insertKeywords(id,desc,ios,android)
      // clone the data object, using its known Config shape
      .subscribe(res => {
        console.log(res);
        this._snackBar.open("Saved", action, {
          duration: 1000
        });
      });
  }
  updateQuestion(data, action: string) {
    this.kiddictionService.updateQuestion(data)
      // clone the data object, using its known Config shape
      .subscribe(res => {
        console.log(res);
        this._snackBar.open("Saved", action, {
          duration: 1000
        });
      });
  }
}
export interface AnswerOptionData {
  animal: string;
  name: string;
}