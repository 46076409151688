import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { RepositoryService } from '../shared/repository.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../common/confirmation-dialog/confirmation-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-teacher',
  templateUrl: './teacher.component.html',
  styleUrls: ['./teacher.component.css']
})
export class TeacherComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isLoading = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  sortBy = 'created_at';
  sortOrder = 'desc';
  keyword = '';
  pageSizeOptions: number[] = [10, 20, 50, 100];
  public displayedColumns = ['picture', 'salutation', 'first_name', 'last_name', 'email', 'school_name', 'city', 'updated_at', 'deactive'];
  public dataSource = new MatTableDataSource<any>();

  constructor(private repoService: RepositoryService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {
    this.pageSize = environment.perPage;
  }

  ngOnInit() {
    //Load initial data
    this.loadData();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  loadData() {
    this.isLoading = true;
    let URL = `admin/get-teachers?keyword=${this.keyword}&sortBy=${this.sortBy}&sortOrder=${this.sortOrder}&page=${this.currentPage}&perPage=${this.pageSize}`;
    this.repoService.getData(URL)
      .subscribe((data: any) => {
        this.dataSource.data = data.data;
        setTimeout(() => {
          this.paginator.pageIndex = this.currentPage;
          this.paginator.length = data.total;
        });
        this.isLoading = false;
      }, error => {
        console.log(error);
        this.isLoading = false;
      });
  }
  public redirectToDetails = (id: string) => {

  }

  public redirectToUpdate = (id: string) => {

  }

  public redirectToDeactivate = (id: string) => {
    console.log(id)
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '250px',
      data: { name: "Test", confirm: true, message: "Are you sure you want to mark this educator as unverified?" },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        this.repoService.update('admin/deactivate-teacher', { teacher_id: id })
          .subscribe((data: any) => {
            this._snackBar.open("Saved", "Dectivated", {
              duration: 1000
            });
            this.loadData();
          }, error => {
            console.log(error);
          });
      }
    });
  }

  public redirectToActivate = (id: string) => {
    console.log(id)
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '250px',
      data: { name: "Test", confirm: true, message: "Are you sure you want to mark this educator as verified?" },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        this.repoService.update('admin/activate-teacher', { teacher_id: id })
          .subscribe((data: any) => {
            this._snackBar.open("Saved", "Activated", {
              duration: 1000
            });
            this.loadData();
          }, error => {
            console.log(error);
          });
      }
    });
  }
  public setActivated = (id: string) => {
    console.log(id)
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '250px',
      data: { name: "Test", confirm: true, message: "Are you sure you want to activate this educator?" },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        this.repoService.update('admin/activate', { teacher_id: id })
          .subscribe((data: any) => {
            this._snackBar.open("Saved", "Activated", {
              duration: 1000
            });
            this.loadData();
          }, error => {
            console.log(error);
          });
      }
    });
  }
  public setDeactivated = (id: string) => {
    console.log(id)
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '250px',
      data: { name: "Test", confirm: true, message: "Are you sure you want to deactivate this educator?" },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        this.repoService.update('admin/deactivate', { teacher_id: id })
          .subscribe((data: any) => {
            this._snackBar.open("Saved", "Dectivated", {
              duration: 1000
            });
            this.loadData();
          }, error => {
            console.log(error);
          });
      }
    });
  }

  customSort(event) {
    console.log(event)
    this.sortBy = event.active;
    this.sortOrder = event.direction;
    this.loadData();
  }
  pageChanged(event: PageEvent) {
    console.log({ event });
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.loadData();
  }
  public doFilter = (value: string) => {

    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  getCity(item) {
    if (item.city == "" || item.city == null) {
      return item.address;
    } else {
      return item.city;
    }
  }
  getSchool(item) {
    if (item.school) {
      return item.school.school_name;
    } else {
      return null;
    }
  }
}
