import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DatePipe } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMenuModule } from '@angular/material/menu';
import { Routes, RouterModule } from '@angular/router';
import { ParentUserComponent } from './parent-user/parent-user.component';
import { ChildrenComponent } from './children/children.component';
import { ParentUsersService } from './services/parent-users.service';
import { NotificaitonsService } from './services/notifications.service';
import { KiddictionService } from './services/kiddiction.service';
import { ReportsService } from './services/reports.service'
import { LoginService } from './services/login.service';
import { CommonModule } from "@angular/common";
import { AuthInterceptor } from '../app/interceptor/auth.interceptor';
import { LoginComponent } from './login/login.component';
import { ReportsComponent } from './reports/reports.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NotificationsComponent } from './notifications/notifications.component';
import { KiddictionComponent } from './kiddiction/kiddiction.component';
import { PossibleAnswerComponent } from './kiddiction/possible-answer/possible-answer.component';
import { KeywordsComponent } from './kiddiction/keywords/keywords.component';
import { InlineEditAnswerComponent } from './kiddiction/inline-edit-answer/inline-edit-answer.component';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { QuestionsComponent } from './questions/questions.component';
import { AnswerOptionsComponent } from './questions/answer-options/answer-options.component';
import { LogoutComponent } from './logout/logout.component';
import { TrComponent } from './tr/tr.component';
import { OwnerComponent } from './owner/owner.component';
import { TeacherComponent } from './teacher/teacher.component';
import { ConfirmationDialogComponent } from './common/confirmation-dialog/confirmation-dialog.component';
import { PossiblePronounciationComponent } from './common/possible-pronounciation/possible-pronounciation.component';
import { FilteredWordsComponent } from './common/filtered-words/filtered-words.component';
import { NotificationsNewComponent } from './notifications-new/notifications-new.component';
import { FilterComponent } from './notifications-new/filter/filter.component';
import { PreviousVisitsPopupComponent } from './reports/previous-visits-popup/previous-visits-popup.component';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';

@NgModule({
  declarations: [
    AppComponent, ParentUserComponent, ChildrenComponent, LoginComponent, ReportsComponent, NotificationsComponent, KiddictionComponent, PossibleAnswerComponent, KeywordsComponent, InlineEditAnswerComponent, QuestionsComponent, AnswerOptionsComponent, LogoutComponent, TrComponent, OwnerComponent, TeacherComponent, ConfirmationDialogComponent, PossiblePronounciationComponent, NotificationsNewComponent, FilterComponent, PreviousVisitsPopupComponent, FilteredWordsComponent
  ],
  exports: [
    RouterModule,
    MatSortModule  
  ],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    MatToolbarModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatSortModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatTableModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    // RouterModule.forRoot(routes),
    ReactiveFormsModule,
    NgbModule,
    MatRadioModule,
    TimepickerModule.forRoot()
  ],
  providers: [
    ParentUsersService,
    LoginService,
    ReportsService,
    NotificaitonsService,
    KiddictionService,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
