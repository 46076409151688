import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface UsersData {
  name: string;
  id: number;
}

@Component({
  selector: 'inline-edit-answer',
  templateUrl: './inline-edit-answer.component.html',
  styleUrls: ['./inline-edit-answer.component.css']
})
export class InlineEditAnswerComponent{

  action: string;
  local_data: any;
  /** Overrides the comment and provides a reset value when changes are cancelled. */
  constructor(
    public dialogRef: MatDialogRef<InlineEditAnswerComponent>,
    //@Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data) {
    console.log(data);
    this.local_data = { ...data };
    this.action = this.local_data.action;
  }



  doAction() {
    console.log({ event: this.action, data: this.local_data });
    this.dialogRef.close({ event: this.action, data: this.local_data });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }
}
