import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { ReportsService } from '../services/reports.service'
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { RepositoryService } from '../shared/repository.service';
import { AuthService } from '../services/auth.service';
import { User } from '../Models/user.model';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { PreviousVisitsPopupComponent } from './previous-visits-popup/previous-visits-popup.component';
@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
  currentUser: User;
  @ViewChild('searchInput', { static: false }) searchInput;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isLoading = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  sortBy = 'created_at';
  sortOrder = 'desc';
  url = 'session-dates2';
  pageTitle = 'Session Date';
  keyword = '';
  isActive = 1;
  pageSizeOptions: number[] = [10, 20, 50, 100];
  public displayedColumns = ['user_id', 'signed_up', 'last_visited', 'sessions'];

  public dataSource = new MatTableDataSource<any>();

  constructor(
    private repoService: RepositoryService,
    public dialog: MatDialog,
    private authService: AuthService
  ) {
    this.currentUser = this.authService.currentUserValue;
  }

  ngOnInit(): void {
    this.loadData();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    this.searchInput.valueChanges // this is (modelChange)
      .pipe(debounceTime(400))
      .pipe(distinctUntilChanged())
      .subscribe((value) => {
        this.keyword = value;
        this.currentPage = 0;
        // this.isFilterAction = true;        
        this.loadData();
      });
  }

  loadData() {
    this.isLoading = true;
    if (this.url !== 'session-dates2') {
      //this.displayedColumns = ['user_id', 'total_duration', 'story_duration', 'story_visited', 'chapters_visited', 'questions_answered', 'questions_answered_correctly'];
      this.displayedColumns = ['user_data.id', 'user_data.name', 'user_data.email', 'name', 'time_spent.total_duration', 'time_spent.story_duration', 'stories', 'chapters', 'total_answer', 'correct_answer', 'user_data.country'];
      this.pageTitle = 'Session Detail';
    } else {
      this.displayedColumns = ['user_id', 'name', 'email', 'signed_up', 'last_visited', 'sessions'];
      this.pageTitle = 'Session Date';
    }
    let URL = `admin/` + this.url + `?keyword=${this.keyword}&sortBy=${this.sortBy}&sortOrder=${this.sortOrder}&page=${this.currentPage}&perPage=${this.pageSize}&is_active=${this.isActive}`;
    this.repoService.getData(URL)
      .subscribe((data: any) => {
        console.log(data.data)
        this.dataSource.data = data.data;
        setTimeout(() => {
          this.paginator.pageIndex = this.currentPage;
          this.paginator.length = data.total;
        });
        this.isLoading = false;
      }, error => {
        console.log(error);
        this.isLoading = false;
      });
  }

  radioSelect() {
    this.keyword = null;
    this.loadData();
  }
  customSort(event) {
    console.log(event)
    this.sortBy = event.active;
    this.sortOrder = event.direction;
    this.loadData();
  }
  pageChanged(event: PageEvent) {
    console.log({ event });
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.loadData();
  }
  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }
  openDialog(sessions) {
    const dialogRef = this.dialog.open(PreviousVisitsPopupComponent, {
      data: {
        sessions: sessions
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  /*@ViewChild('TABLE') table: ElementRef;
  @ViewChild('datesPaginator', { static: false }) datesPaginator: MatPaginator;
  @ViewChild('sessionsPaginator', { static: false }) sessionsPaginator: MatPaginator;
  sessionDates = false;
  sessionDetails = false;
  constructor(private reportsService: ReportsService, private datePipe: DatePipe, private cdRef: ChangeDetectorRef) { }
  sessionDatesData = [];
  sessionDetailsData = [];
  displayedColumnsDatesData = ['user_id', 'signed_up', 'last_visited', 'sessions'];
  displayedColumnsDetailsData = ['user_id', 'total_duration', 'story_duration', 'story_visited', 'chapters_visited', 'questions_answered', 'questions_answered_correctly'];
  excelSessionDatesData: MatTableDataSource<Element>;
  excelSessionDetailsData: MatTableDataSource<Element>;
  DatesfileName = 'sessionDates.xlsx';
  DetailsfileName = 'sessionDetails.xlsx';
  isSessionsDatesLoading: boolean = true;
  isSessionDetailsLoading: boolean = true;
  ngOnInit(): void {

  }
  
  /* exportexcel(){
     //let table = document.getElementById('table');
     //const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(this.table.nativeElement);
 
     let data;
     if(this.sessionDates){
     data=this.sessionDatesData.map(a=>{
       return {
         ...a,
         sessions:a.sessions.map(session=>{
           return this.datePipe.transform(session,'MMM d, y, h:mm:ss a ')
         }).join((", ")),
         sessions_count:a.sessions.length +1
         //sessions:(a.sessions| date:'medium').join(", ")
       }
     })
     const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet(data, {header:['user_id', 'signed_up', 'last_visited', 'sessions','sessions_count']});
     // console.log("test ");
     // console.log(ws);
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
     XLSX.writeFile(wb, this.DatesfileName);
     // const workSheet = XLSX.utils.json_to_sheet(this.sessionDatesData, {header:['user_id', 'signed_up', 'last_visited', 'sessions']});
     // const workBook: XLSX.WorkBook = XLSX.utils.book_new();
     // XLSX.utils.book_append_sheet(workBook, workSheet, 'SheetName');
     // XLSX.writeFile(workBook, 'filename.xlsx');
   }else{
     data=this.sessionDetailsData.map(a=>{
 
       return {
         ...a,
         story_visited:a.story_visited.join(", "),
         chapters_visited:a.chapters_visited.join(", ")
       }
     })
     const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet(data, {header:['user_id', 'total_duration', 'story_duration', 'story_visited','chapters_visited']});
     // console.log("test ");
     // console.log(ws);
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
     XLSX.writeFile(wb, this.DetailsfileName);
   }
 }*/
}
