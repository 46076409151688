import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
   providedIn: 'root'
})
export class ExpenseGuard implements CanActivate {

   constructor(private authService: AuthService, private router: Router) { }

   canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): boolean | UrlTree {
      let url: string = state.url;

      return this.checkLogin(url);
   }

   checkLogin(url: string): true | UrlTree {
      let val: string = localStorage.getItem('isUserLoggedIn');
      console.log("Url: " + url)
      if (val != null && val == "true") {
         if (url == "/login") {
            this.router.parseUrl('/parent-users');
         } else {
            return true;
         }
      } else {
         return this.router.parseUrl('/login');
      }
   }
}