import {
  Injectable
} from '@angular/core';
import {
  HttpClient
} from '@angular/common/http';
import {
  Observable,
  throwError
} from 'rxjs';
import {
  catchError,
  retry
} from 'rxjs/operators';
import { User } from '../Models/user.model'
import { environment } from 'src/environments/environment';
interface Result {
  data: {
    users: User[];
  };
  message?: string;
  status?: Boolean;
}
@Injectable()
export class ParentUsersService {
  constructor(private http: HttpClient) { }
  sendNotification(user_id: number, title: string, body: string) {
    return this.http.post(environment.apiURL + '/admin/send-notification', { user_id, title, body });
  }

}
