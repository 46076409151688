import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { User } from '../Models/user.model';
import { ParentUsersService } from '../services/parent-users.service'
import { AuthService } from '../services/auth.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RepositoryService } from '../shared/repository.service';
import { ConfirmationDialogComponent } from '../common/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-parent-user',
  templateUrl: './parent-user.component.html',
  styleUrls: ['./parent-user.component.css']
})
export class ParentUserComponent implements OnInit {
  currentUser: User;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isLoading = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  sortBy = 'created_at';
  sortOrder = 'desc';
  keyword = '';
  isActive = 1;
  pageSizeOptions: number[] = [10, 20, 50, 100];
  public displayedColumns = ['name', 'email', 'created_at', 'country', 'timezone_abbreviation', 'actions'];

  public dataSource = new MatTableDataSource<any>();

  constructor(
    private parentsUserService: ParentUsersService,
    public dialog: MatDialog,
    private repoService: RepositoryService,
    private _snackBar: MatSnackBar,
    private authService: AuthService
  ) {
    this.currentUser = this.authService.currentUserValue;
  }

  ngOnInit(): void {
    this.loadData();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  loadData() {
    this.isLoading = true;
    let URL = `admin/users?keyword=${this.keyword}&sortBy=${this.sortBy}&sortOrder=${this.sortOrder}&page=${this.currentPage}&perPage=${this.pageSize}&is_active=${this.isActive}`;
    this.repoService.getData(URL)
      .subscribe((data: any) => {
        console.log(data.data)
        this.dataSource.data = data.data;
        setTimeout(() => {
          this.paginator.pageIndex = this.currentPage;
          this.paginator.length = data.total;
        });
        this.isLoading = false;
      }, error => {
        console.log(error);
        this.isLoading = false;
      });
  }

  loadDataWith() {
    this.sortBy = 'created_at';
    this.sortOrder = 'desc';
    this.keyword = '';
    this.currentPage = 0;
    this.loadData();
  }
  customSort(event) {
    console.log(event)
    this.sortBy = event.active;
    this.sortOrder = event.direction;
    this.loadData();
  }
  pageChanged(event: PageEvent) {
    console.log({ event });
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.loadData();
  }
  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  public redirectToDeactivate = (id: string) => {
    console.log(id)
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '250px',
      data: { name: "Test", confirm: true },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        this.repoService.update('admin/deactivate-user', { id: id })
          .subscribe((data: any) => {
            this._snackBar.open("Saved", "Dectivated", {
              duration: 1000
            });
            this.loadData();
          }, error => {
            console.log(error);
          });
      }
    });
  }

  public redirectToActivate = (id: string) => {
    console.log(id)
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '250px',
      data: { name: "Test", confirm: true },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        this.repoService.update('admin/activate-user', { id: id })
          .subscribe((data: any) => {
            this._snackBar.open("Saved", "Activated", {
              duration: 1000
            });
            this.loadData();
          }, error => {
            console.log(error);
          });
      }
    });
  }
  /*  onActivatedUsersClick() {
      this.deactivatedUsers = false;
      this.activeUsers = true;
    }
    onDeactivatedUsersClick() {
      this.deactivatedUsers = true;
      this.activeUsers = false;
    }
    deactivateUser(id: number) {
      console.log(id);
      this.parentsUserService.deactivateUser(id).subscribe(() => {
  
        this.parentsUserService.getUsers()
          // clone the data object, using its known Config shape
          .subscribe(res => {
            console.log(res);
            this.users = res.data.users;
            this.userData = new MatTableDataSource(this.users);
            console.log(this.users);
  
          });
        this.parentsUserService.getDeactivatedUsers()
          // clone the data object, using its known Config shape
          .subscribe(res => {
            //console.log(res);
            this.deactiveUsers = res.data.users;
            this.deactiveUserData = new MatTableDataSource(this.deactiveUsers);
            console.log(this.deactiveUsers);
  
          });
      });
  
    }
    activateUser(id: number) {
      console.log(id);
      this.parentsUserService.activateUser(id).subscribe(() => {
  
        this.parentsUserService.getUsers()
          // clone the data object, using its known Config shape
          .subscribe(res => {
            console.log(res);
            this.users = res.data.users;
            this.userData = new MatTableDataSource(this.users);
            console.log(this.users);
  
          });
        this.parentsUserService.getDeactivatedUsers()
          // clone the data object, using its known Config shape
          .subscribe(res => {
            //console.log(res);
            this.deactiveUsers = res.data.users;
            this.deactiveUserData = new MatTableDataSource(this.deactiveUsers);
            console.log(this.deactiveUsers);
  
          });
      });
  
    }*/


}
