import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { RepositoryService } from "src/app/shared/repository.service";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";

interface FilteredWord {
  word: string;
  id: number;
}

@Component({
  selector: "app-filtered-words",
  templateUrl: "./filtered-words.component.html",
  styleUrls: ["./filtered-words.component.css"],
})
export class FilteredWordsComponent implements OnInit {
  public word = "";
  public type = "whole";
  public wholeWords: Array<FilteredWord> = [];
  public anyWords: Array<FilteredWord> = [];
  public loading = false;

  constructor(
    public dialogRef: MatDialogRef<any>,
    public httpService: RepositoryService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.getWords();
  }

  public getWords() {
    this.loading = true;
    this.wholeWords = [];
    this.anyWords = [];
    this.httpService.getData("admin/filtered-words").subscribe((res: any) => {
      if (res.status) {
        res.data.forEach((x) => {
          if (x.type == "any") {
            this.anyWords.push(x);
          } else {
            this.wholeWords.push(x);
          }
        });
      }
      this.loading = false;
    });
  }
  public addWord() {
    const word = this.word && this.word.trim().toLowerCase();
    if (word) {
      this.httpService
        .create("admin/filtered-words", { type: this.type, word })
        .subscribe((res: any) => {
          this.getWords();
          this.clearForm();
          this._snackBar.open("Added", "Word has been added", {
            duration: 3000,
          });
        });
      }
  }

  public confirmRemoval(id: number) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "250px",
      data: {
        name: "Remove word?",
        confirm: true,
        message: "Are you sure you want to remove this word?",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.removeWord(id);
      }
    });
  }

  private removeWord(id: number) {
    this.loading = true;
    this.httpService
      .delete(`admin/filtered-words/${id}`)
      .subscribe((res: any) => {
        this.getWords();
        this._snackBar.open("Removed", "Word has been removed", {
          duration: 3000,
        });
      });
  }

  private clearForm() {
    this.word = "";
    this.type = "any";
  }
}
