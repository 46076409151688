import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { KiddictionData } from '../Models/kiddictionData.model'
interface TRDataResult {
  data: KiddictionData[];
  message?: string;
  status?: Boolean;
}

@Injectable({
  providedIn: 'root'
})
export class TrService {

  constructor(private httpClient: HttpClient) { }

  getTRData(config) {
    return this.httpClient.get<TRDataResult>(environment.apiURL + '/crm/tr-data', { params: config });
  }
  updateActualAnswer(data) {
    return this.httpClient.put(environment.apiURL + '/admin/update-tr-actual-answer', { id: data.id, actual_answer: data.description });
  }  
}
