import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { User } from '../Models/user.model';
import { AuthService } from '../services/auth.service';
import { NotificaitonsService } from '../services/notifications.service';
import { RepositoryService } from '../shared/repository.service';
import { FilterComponent } from './filter/filter.component';

@Component({
  selector: 'app-notifications-new',
  templateUrl: './notifications-new.component.html',
  styleUrls: ['./notifications-new.component.css']
})
export class NotificationsNewComponent implements OnInit {
  @ViewChild('searchInput', { static: true }) searchInput;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('fileInput', { static: false }) private fileInput: ElementRef<HTMLInputElement>;
  currentUser: User;
  isLoading = false;
  isSyncing = false;
  showFilter = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  sortBy = 'fk_child_id';
  sortOrder = 'desc';
  keyword = '';
  pageSizeOptions: number[] = [10, 20, 50, 100, 200];
  // public displayedColumns = ['select', 'id', 'name', 'total_duration', 'questions_answered', 'stories_visited', 'chapters_visited'];
  public displayedColumns = ['select', 'fk_child_id', 'user_data.name', 'user_data.email', 'user_data.country', 'child.name', 'story_child', 'highest_chapter_visted', 'last_activity_date', 'total_duration', 'correct_answer'];
  public dataSource = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);
  lastSyncRecord: any;
  filterData: any;
  story: any = null;
  chapter: any = null;
  totalDuration: any = null;
  filterCountry: any = null;
  title = '';
  body = '';
  public image: FileList;

  constructor(
    private authService: AuthService,
    private repoService: RepositoryService,
    public dialog: MatDialog,
    private modalService: NgbModal,
    private notificationsService: NotificaitonsService
  ) {
    this.currentUser = this.authService.currentUserValue;
  }

  ngOnInit(): void {
    this.lastSyncData();
    this.getFilterData();
    this.loadData();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    this.searchInput.valueChanges // this is (modelChange)
      .pipe(debounceTime(400))
      .pipe(distinctUntilChanged())
      .subscribe((value) => {
        this.keyword = value;
        this.currentPage = 0;
        // this.isFilterAction = true;        
        this.loadData();
      });
  }

  /*loadDataAll() {
    this.isLoading = true;
    let URL = `admin/notifications?keyword=${this.keyword}&sortBy=${this.sortBy}&sortOrder=${this.sortOrder}&page=${this.currentPage}&perPage=${this.pageSize}&story=${this.story}&chapter=${this.chapter}&totalDuration=${this.totalDuration}&filterCountry=${this.filterCountry}`;
    this.repoService.getData(URL)
      .subscribe((data: any) => {
        this.dataSource.data = data.data;
        this.getFilterData();
        this.showFilter = true;
        setTimeout(() => {
          this.paginator.pageIndex = this.currentPage;
          this.paginator.length = data.total;
        });
        this.isLoading = false;
      }, error => {
        console.log(error);
        this.isLoading = false;
      });
  }*/

  loadData() {

    this.isLoading = true;
    let URL = `admin/notifications?keyword=${this.keyword}&sortBy=${this.sortBy}&sortOrder=${this.sortOrder}&page=${this.currentPage}&perPage=${this.pageSize}&story=${this.story}&chapter=${this.chapter}&totalDuration=${this.totalDuration}&filterCountry=${this.filterCountry}`;
    this.repoService.getData(URL)
      .subscribe((data: any) => {
        console.log(data.data)
        this.dataSource.data = data.data;
        setTimeout(() => {
          this.paginator.pageIndex = this.currentPage;
          this.paginator.length = data.total;
          //this.dataSource.data.forEach(row => this.selection.select(row));
        });
        this.isLoading = false;
      }, error => {
        console.log(error);
        this.isLoading = false;
      });
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  sendNotificationShow() {
    if (this.selection.selected.length > 0)
      return true;
    else
      return false;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  /*masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
    //this.selectRows();
  }*/
  masterToggle(ref) {
    // console.log(ref);
    // if there is a selection then clear that selection
    if (this.isSomeSelected()) {
      
      this.selection.clear();
    ref.checked=false;
      
    } else {
      this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
    }
    // console.log(ref);
  }
  isSomeSelected() {
    
    return this.selection.selected.length > 0;
  }
  logSelection() {
    this.selection.selected.forEach(s => console.log(s.name));
  }
  customSort(event) {
    console.log(event)
    this.sortBy = event.active;
    this.sortOrder = event.direction;
    this.loadData();
  }
  pageChanged(event: PageEvent) {
    console.log({ event });
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.loadData();
  }
  selectRows() {

    const intIndex = this.dataSource.paginator.pageIndex === 0 ? 0 : (this.dataSource.paginator.pageSize * this.dataSource.paginator.pageIndex);
    let intPageSize = intIndex + this.dataSource.paginator.pageSize;
    if (intPageSize > this.dataSource.filteredData.length) {
      intPageSize = this.dataSource.filteredData.length;
    }
    for (let index = intIndex; index < intPageSize; index++) {
      this.selection.select(this.dataSource.filteredData[index]);
      // this.selectionAmount = this.selection.selected.length;
    }
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(FilterComponent, {
      width: '30%',
      data: {
        story: this.story,
        chapter: this.chapter,
        totalDuration: this.totalDuration,
        filterCountry: this.filterCountry,
        formData: this.filterData
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log(result);
      this.story = result.story;
      this.chapter = result.chapter;
      this.totalDuration = result.totalDuration;
      this.filterCountry = result.filterCountry;
      this.searchResult();
    });
  }
  searchResult() {
    this.isLoading = true;
    this.loadData();
  }
  open(content) {
    this.modalService.open(content);
  }
  getFilterData() {
    let URL = `stories`;
    this.repoService.getData(URL)
      .subscribe((data: any) => {
        console.log(data.data)
        this.filterData = data.data;
        this.showFilter = true;
      }, error => {
        console.log(error);
      });
  }

  sendNotification() {
    this.modalService.dismissAll();
    let updateIds = this.selection.selected.map(({ fk_child_id }) => fk_child_id);
    let filters = {
      story: this.story,
      chapter: this.chapter,
      totalDuration: this.totalDuration,
      filterCountry: this.filterCountry
    };
    this.notificationsService.sendNotification(updateIds, this.title, this.body, this.image && this.image[0], filters).subscribe(res => {
      console.log(res);
    });
    this.title = '';
    this.body = '';
    this.image = null;
    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }
  }


  lastSyncData() {
    this.notificationsService.lastSyncData().subscribe(res => {
      console.log(res);
      this.lastSyncRecord = res.data;
      if (this.lastSyncRecord.is_job_done == 0) {
        this.isSyncing = true;
      }
    });
  }
  syncData() {
    this.isSyncing = true;
    this.notificationsService.syncData().subscribe(res => {
      console.log(res);
    });
  }
}
