import {
  Injectable
} from '@angular/core';
import {
  HttpClient
} from '@angular/common/http';
import {
  Observable,
  throwError
} from 'rxjs';
import {
  catchError,
  retry
} from 'rxjs/operators';
import { User } from '../Models/user.model'
interface Result {
  data: {
    user: User;
    token?: string;
  };

  message?: string;
  status?: Boolean;
}
@Injectable()
export class LoginService {
  constructor(private http: HttpClient) { }
  login(userObj: any) {
    // now returns an Observable of Config
    return this.http.post<Result>('http://stg.api.talkingrhino.com/api/admin/login', userObj);
  }

}
