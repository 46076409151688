export interface Owner {
  date: string;
  name: string;
  age: number;
  question: number;
  saved_answer: string;
  raw_answer: string;
  matched_answer: string;
  kiddiction_score: number;
}

import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { RepositoryService } from '../shared/repository.service';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-owner-list',
  templateUrl: './owner.component.html',
  styleUrls: ['./owner.component.css']
})
export class OwnerComponent implements OnInit, AfterViewInit {

  public displayedColumns = ['date', 'name', 'age', 'question', 'actual_answer', 'raw_answer', 'matched_answer', 'kiddiction_score', 'possible_answer', 'keywords', 'details', 'update', 'delete'];
  public dataSource = new MatTableDataSource<Owner>();
  @ViewChild(MatSort) sort: MatSort;
  constructor(private repoService: RepositoryService) { }

  ngOnInit() {
    this.getAllOwners();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }
  public getAllOwners = () => {
    this.repoService.getData('crm/tr-data')
      .subscribe(res => {
        this.dataSource.data = res as Owner[];
      })
  }

  public redirectToDetails = (id: string) => {

  }

  public redirectToUpdate = (id: string) => {

  }

  public redirectToDelete = (id: string) => {

  }

  customSort(e){
    console.log(e)
  }
  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

}