import {
  Injectable
} from '@angular/core';
import {
  HttpClient
} from '@angular/common/http';
import {
  Observable,
  throwError
} from 'rxjs';
import {
  catchError,
  retry
} from 'rxjs/operators';
import {User} from '../Models/user.model'
import {SessionDates} from '../Models/sessionDates.model'
import {SessionDetails} from '../Models/sessionDetails.model'
import { environment } from 'src/environments/environment';
interface SessionDateResult{
    data:SessionDates[];
    message?:string;
    status?:Boolean;
}
interface SessionDetailsResult{
    data:SessionDetails[];
    message?:string;
    status?:Boolean;

}
@Injectable()
export class ReportsService {
  constructor(private http: HttpClient) {}
  getSessionDates() {
    // now returns an Observable of Config
    return this.http.get < SessionDateResult > (environment.apiURL + '/admin/session-dates');
  }
  getSessionDetails() {
    // now returns an Observable of Config
    return this.http.get < SessionDetailsResult > (environment.apiURL + '/admin/session-details');
  }

}
