import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { KiddictionData } from '../Models/kiddictionData.model'
interface KiddictionDataResult {
  data: KiddictionData[];
  message?: string;
  status?: Boolean;
}
@Injectable()
export class KiddictionService {

  constructor(private httpClient: HttpClient) { }

  getKiddictionData(config) {
    return this.httpClient.get<KiddictionDataResult>(environment.apiURL + '/crm/kiddiction-data', { params: config });
  }
  getAllQuestion(config) {
    return this.httpClient.get(environment.apiURL + '/admin/questions-answers', { params: config });
  }
  updateActualAnswer(data) {
    return this.httpClient.put(environment.apiURL + '/admin/update-actual-answer', { id: data.id, actual_answer: data.description });
  }
  updatePossibleAnswer(data, answer) {
    return this.httpClient.put(environment.apiURL + '/admin/update-possible-answer', { id: data.id, possible_answer: data.description, ios_description: data.ios_description, android_description: data.android_description, answer: answer });
  }
  updateKeywords(data) {
    return this.httpClient.put(environment.apiURL + '/admin/update-keywords', { id: data.id, keywords: data.description, ios_description: data.ios_description, android_description: data.android_description });
  }
  insertPossibleAnswer(id, desc, ios, android) {
    return this.httpClient.post(environment.apiURL + '/admin/insert-possible-answer', { id: id, possible_answer: desc, ios_description: ios, android_description: android });
  }
  insertKeywords(id, desc, ios, android) {
    return this.httpClient.post(environment.apiURL + '/admin/insert-keywords', { id: id, keywords: desc, ios_description: ios, android_description: android });
  }
  updateQuestion(data) {
    return this.httpClient.put(environment.apiURL + '/admin/update-question', { id: data.id, question: data.description, language: data.language, is_active: data.is_active, kiddiction_gibberish_cutoff: data.kiddiction_gibberish_cutoff, kiddiction_repeat_cutoff: data.kiddiction_repeat_cutoff });
  }
  updateAnswer(data) {
    return this.httpClient.put(environment.apiURL + '/admin/update-answer', { id: data.id, answer: data.description });
  }
}
